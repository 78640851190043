import React, {
  forwardRef,
  KeyboardEventHandler,
  LegacyRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button, Form, Input, notification } from 'antd'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Message } from '../../types/storage.type'
import ReactMarkdownContent from '../../routes/chat/components/ReactMarkdownContent/ReactMarkdownContent'
import AddImageByLinkModal from '../UI/Modals/AddImageByLinkModal'
import AddImagesPopover from '../../routes/chat/components/AddImagesPopover/AddImagesPopover'
import SelectedImages from '../../routes/chat/components/SelectedImages/SelectedImages'
import './chat.less'
import { RolesEnum } from '../../config/rolesEnum'
import OpenaiRealtime from '../../routes/public/OpenaiRealtime'
import { PublicChatMode } from '../../types/enums/publick-chat-mode.enum'
import { useAppSelector } from '../../hooks/appHook'
import { useLocation } from 'react-router-dom'

export interface ConversationMessageItem {
  message?: string
  linksImages?: string[]
  fileImages?: File[]
}

interface Props {
  onSend: (values: ConversationMessageItem) => void
  messages: Array<Message> | undefined
  isFetching: boolean
  placeholder: string
  isEditable: boolean
  successMessageSend?: boolean
  isPublic?: boolean
  refetchMessages: () => void
  conversationId?: string
  isVoiceModeAllowed?: boolean
}

const Chat = forwardRef(function Chat(
  {
    onSend,
    messages,
    isFetching,
    placeholder,
    isEditable,
    successMessageSend,
    refetchMessages,
    isPublic,
    conversationId,
    isVoiceModeAllowed,
  }: Props,
  ref: LegacyRef<HTMLDivElement>,
) {
  const [form] = Form.useForm()
  const location = useLocation()
  /** State */
  const [uploadedImages, setUploadedImages] = useState<Array<File | string>>([])
  const [chatMode, setChatMode] = useState<PublicChatMode>(PublicChatMode.chat)

  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const { user } = useAppSelector((state) => state.profile)

  const scrollToLastMessageBeginning = () => {
    setTimeout(() => {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'start',
        })
      }
    }, 500)
  }

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search)

    const conversationId = queryParams.get('conversationId') || ''

    return {
      conversationId,
    }
  }

  const isRealtimeVisible =
    user?.role == RolesEnum.ADMIN && (conversationId || getQueryParams().conversationId) && isVoiceModeAllowed
  const messagesListLength = messages?.length || -1

  /** Handlers */
  const handleFileUpload = (files: File[]) => {
    const validFiles = files.filter(
      (file) =>
        ['image/png', 'image/jpeg', 'image/webp', 'image/gif'].includes(file.type) && file.size <= 20 * 1024 * 1024,
    )
    if (uploadedImages.length + validFiles.length <= 5) {
      setUploadedImages((prev) => [...prev, ...validFiles])
    } else {
      notification.error({
        message: 'Maximum number of added images cannot exceed 5',
      })
    }
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFileUpload(acceptedFiles)
    },
    [handleFileUpload, uploadedImages],
  )

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    notification.error({
      message: rejectedFiles[0].errors[0].message,
    })
  }

  /** Use custom hooks */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/gif': [],
    },
    maxSize: 20 * 1024 * 1024,
    noClick: true,
  })

  const handleImageLinkAdd = useCallback((imageLink: string) => {
    /** Validate image link before adding */
    setUploadedImages((prev) => [...prev, imageLink]) // Placeholder file object for preview
  }, [])

  const removeImage = useCallback((index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      form.submit()
    }
  }

  const onSubmit = async (values: { newMessage: string }) => {
    const linksImages = uploadedImages.filter((image) => typeof image === 'string' && image) as string[]
    const fileImages = uploadedImages.filter((image) => typeof image !== 'string' && image) as File[]

    if (![...linksImages, ...fileImages].length && !values.newMessage.trim()) {
      return
    }

    const request: ConversationMessageItem = {
      message: values.newMessage,
      linksImages,
      fileImages,
    }

    onSend(request)
  }

  /** UseEffect */
  useEffect(() => {
    if (!isFetching) {
      form.resetFields()
      scrollToLastMessageBeginning()
    }
  }, [isFetching])

  useEffect(() => {
    if (successMessageSend && !isFetching) {
      setUploadedImages([])
      form.resetFields()
      scrollToLastMessageBeginning()
    }
  }, [successMessageSend, isFetching])

  return (
    <div className="space-chat-container">
      <div id="chat-container" ref={ref} className="chat-messages-list-container">
        <ul className="chat-messages-list">
          {messages?.map((message: Message, index) => {
            const isLastMessage = index === messagesListLength - 1
            return (
              <ReactMarkdownContent
                message={message}
                key={index}
                showRoles={!isPublic}
                lastMessageRef={isLastMessage ? lastMessageRef : undefined}
              />
            )
          })}
        </ul>
      </div>
      {isEditable && (
        <>
          <Form form={form} layout={'vertical'} onFinish={onSubmit} disabled={isFetching}>
            <div className="form-item-container" {...getRootProps()}>
              {uploadedImages?.length > 0 ? <SelectedImages images={uploadedImages} removeImage={removeImage} /> : null}
              <input {...getInputProps()} />
              <Form.Item name="newMessage">
                <Input.TextArea
                  id="form-input"
                  autoFocus={true}
                  name="chat-input"
                  placeholder={placeholder}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  className="form-item-message-textarea"
                  onPressEnter={handleKeyPress}
                  disabled={chatMode === PublicChatMode.voice}
                />
              </Form.Item>
              <AddImagesPopover
                isFullSelectedImages={uploadedImages.length === 5}
                onDrop={onDrop}
                disabled={isFetching}
              />
            </div>
            <div className="chat-buttons-container">
              {isRealtimeVisible && (
                <OpenaiRealtime
                  conversationId={conversationId || getQueryParams().conversationId}
                  chatMode={chatMode}
                  setChatMode={(v: PublicChatMode) => setChatMode(v)}
                  refetchMessages={refetchMessages}
                />
              )}
              {chatMode === PublicChatMode.chat && (
                <Button
                  id="form-button"
                  block
                  type="primary"
                  htmlType="submit"
                  loading={isFetching}
                  disabled={isFetching}
                >
                  Send
                </Button>
              )}
            </div>
          </Form>
          <AddImageByLinkModal handleImageLinkAdd={handleImageLinkAdd} />
        </>
      )}
    </div>
  )
})

export default memo(Chat)
