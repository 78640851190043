import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import {
  useDeleteConversationMutation,
  useEditConversationMutation,
  useLazyGetConversationByIdQuery,
} from '../../store/api/conversation.api'
import { useNavigate, useParams } from 'react-router-dom'
import ChatComponent, { ConversationMessageItem } from '../../components/Chat/Chat'
import { Button, Popconfirm, Table } from 'antd'
import './chat.less'
import { useAppSelector } from '../../hooks/appHook'
import Loading from '../../components/UI/Loading'
import { ADMIN_ACCOUNT_ID, ADMIN_USER_ID } from '../../config/consts'
import { LocalStorage } from 'ts-localstorage'
import { useLazyGetAccountOwnerQuery } from '../../store/api/admin/accounts.api'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'

const columns = [
  {
    title: 'Support user',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'Customer name',
    dataIndex: 'customerName',
    key: 'customerName',
  },
  {
    title: 'Customer email',
    dataIndex: 'customerEmail',
    key: 'customerEmail',
  },
]

const Chat = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)

  /** Storage */
  const { user } = useAppSelector((state) => state.profile)
  const { accountId, role } = user

  /** Storage actions */
  const [getConversationById, getConversationByIdQueryResult] = useLazyGetConversationByIdQuery()

  const [editConversation, editConversationMutationResult] = useEditConversationMutation()

  const [deleteConversation] = useDeleteConversationMutation()
  const [getAccountOwner, getAccountOwnerQueryResult] = useLazyGetAccountOwnerQuery()

  useErrorMessage(
    'Something went wrong',
    editConversationMutationResult.error || getConversationByIdQueryResult.error || getAccountOwnerQueryResult.error,
  )

  const isLoginButtonVisible =
    role === 'admin' &&
    getConversationByIdQueryResult.data?.accountId !== accountId &&
    !LocalStorage.getItem(ADMIN_USER_ID)

  const { conversationId } = useParams()

  const listRef = useRef<HTMLDivElement>(null)

  const messages = useMemo(() => {
    if (role !== 'admin') {
      return getConversationByIdQueryResult.data?.messages?.filter(
        (message) => ['user', 'assistant'].includes(message.role) && message.content,
      )
    }
    return getConversationByIdQueryResult.data?.messages
  }, [getConversationByIdQueryResult.data?.messages])

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight
    }
  }

  const handleOnLogin = () => {
    LocalStorage.setItem(ADMIN_USER_ID, getAccountOwnerQueryResult.data?.userId)
    LocalStorage.setItem(ADMIN_ACCOUNT_ID, getConversationByIdQueryResult.data?.accountId)
    window.location.reload()
  }

  const handleDeleteConversation = async () => {
    if (conversationId) {
      await deleteConversation(conversationId)
      navigate('/conversations')
    }
  }

  const send = useCallback(
    (values: ConversationMessageItem) => {
      if (getConversationByIdQueryResult.data?.id) {
        editConversation({
          body: { supportUserResponse: values.message?.trim(), unread: false },
          id: getConversationByIdQueryResult.data.id,
        }).then(() => {
          if (conversationId) {
            getConversationById({ id: conversationId }).then(() => scrollToBottom())
          }
        })
      }
    },
    [getConversationByIdQueryResult.data],
  )

  useEffect(() => {
    if (!conversationId) {
      return
    }
    getConversationById({ id: conversationId }).then((res) => {
      setIsLoading(false)
      if (res?.data?.id && role === 'admin') {
        getAccountOwner(res.data.accountId)
      }
      if (res?.data?.unread) {
        editConversation({ body: { unread: false }, id: res.data.id })
      }
    })
  }, [])

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <AiHelperHeader title="Conversations" />
      <div id="conversation-container">
        {isLoginButtonVisible ? (
          <div className="login-button-container">
            <Button type="text" style={{ color: '#F74555' }} onClick={handleOnLogin}>
              Login as {getConversationByIdQueryResult.data?.accountId}
            </Button>
          </div>
        ) : null}
        {getConversationByIdQueryResult.data?.customerEmail ? (
          <Table
            id="conversation-table"
            dataSource={[getConversationByIdQueryResult.data]}
            columns={columns}
            pagination={false}
          />
        ) : (
          <></>
        )}
        {getConversationByIdQueryResult.data?.id && (
          <ChatComponent
            onSend={send}
            messages={messages}
            isFetching={editConversationMutationResult.isLoading}
            ref={listRef}
            placeholder="Reply to customer"
            isEditable={Boolean(getConversationByIdQueryResult.data.customerEmail)}
            refetchMessages={() => getConversationById({ id: conversationId || '' })}
          />
        )}
        <Popconfirm
          title="Delete the chat"
          description="Are you sure you want to delete this chat? This action is unreverisble."
          onConfirm={handleDeleteConversation}
          okText="Delete"
          cancelText="Cancel"
        >
          <Button danger type="primary" style={{ marginTop: 10 }}>
            Delete
          </Button>
        </Popconfirm>
      </div>
    </>
  )
}

export default Chat
